var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.gen_about_title))]),
    _vm._v(" "),
    _c("div", { staticClass: "about-text" }, [
      _vm._v(_vm._s(_vm.gen_about_text)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }