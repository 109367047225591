<template>
  <div class="container">
    <h1>{{ gen_about_title }}</h1>
    <div class="about-text">{{ gen_about_text }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    gen_about_title() {
      return this.$store.getters.getGeneral('about_title')
    },
    gen_about_text() {
      return this.$store.getters.getGeneral('about')
    }
  }
}
</script>

<style lang="scss" scoped>
.about-text {
  white-space: pre-wrap;
}
</style>
